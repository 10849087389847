import SeoHelmet from "../components/SeoHelmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./Homepage.css";

const Homepage = () => {
    return (
        <div className="Container">
            <SeoHelmet title="InstanceOf" description="InstanceOf | Homepage" />
            <Navbar />

            <div className="Centered-Content" style={{justifyContent: "center"}}>
                <span role="img" aria-label="heart">
                    {" "}
                    ❤️{" "}
                </span>
            </div>

            <Footer />
        </div>
    );
};

export default Homepage;
