import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './pages/About';
import Contact from './pages/Contact';
import Homepage from './pages/Homepage';
import TechCorner from './pages/TechCorner';
import TravelCorner from './pages/TravelCorner';
import AutoTabOpener from './pages/AutoTabOpener';
import FinanceInternPrivacy from './pages/FinanceInternPrivacy';
import BirthdayReminderPrivacy from './pages/BirthdayReminderPrivacy';
import BirthdayReminder from './pages/BirthdayReminder';

class App extends Component {
    constructor(props) {
        super(props);

        initializeReactGA();
    }

    render() {
        return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/tech-corner" element={<TechCorner />} />
                <Route path="/travel-corner" element={<TravelCorner />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/tech-corner/auto-tab-opener" element={<AutoTabOpener />} />
                <Route path="/tech-corner/birthday-reminder" element={<BirthdayReminder />} />
                <Route path="/tech-corner/finance-intern/privacy" element={<FinanceInternPrivacy />} />
                <Route path="/tech-corner/birthday-reminder/privacy" element={<BirthdayReminderPrivacy />} />
            </Routes>
        </BrowserRouter>
        )
}
}
  
  function initializeReactGA() {
    ReactGA.initialize('G-7CC7L31WW5');
    ReactGA.send({ hitType: "pageview", page: "/" });
  }
  
  export default App;
