import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
import {
    Typography,
    Divider,
    CardActionArea,
    Card,
    CardMedia,
} from "@mui/material";
import SeoHelmet from "../components/SeoHelmet";
import chrome from "../static/chrome.png";
import firefox from "../static/firefox.png";
import edge from "../static/edge.png";
import opera from "../static/opera.png";

const AutoTabOpener = () => {
    const goToChrome = (newTab) => {
        ReactGA.event({
            category: "AutoTabOpener",
            action: "User pressed Chrome",
        });
        window.open(
            "https://chrome.google.com/webstore/detail/automatic-tab-opener/hkjobkeceflhccchhjkfofhbcaaaccag?utm_source=instanceof",
            newTab ? "_blank" : "_self"
        );
    };

    const goToFirefox = (newTab) => {
        ReactGA.event({
            category: "AutoTabOpener",
            action: "User pressed Firefox",
        });
        window.open(
            "https://addons.mozilla.org/en-US/firefox/addon/automatic-tab-opener?utm_source=instanceof",
            newTab ? "_blank" : "_self"
        );
    };

    const goToEdge = (newTab) => {
        ReactGA.event({
            category: "AutoTabOpener",
            action: "User pressed Edge",
        });
        window.open(
            "https://microsoftedge.microsoft.com/addons/detail/automatic-tab-opener/dhabkgfndffndlhihfpijpcfbdpiibgd?utm_source=instanceof",
            newTab ? "_blank" : "_self"
        );
    };

    const goToOpera = (newTab) => {
        ReactGA.event({
            category: "AutoTabOpener",
            action: "User pressed Opera",
        });
        window.open(
            "https://addons.opera.com/pt/extensions/details/automatic-tab-opener?utm_source=instanceof",
            newTab ? "_blank" : "_self"
        );
    };

    return (
        <div className="Container">
            <SeoHelmet
                title="InstanceOf | Automatic Tab Opener"
                description="Automatic Tab Opener Browser Extension"
            />
            <Navbar />

            <div
                className="Centered-Content"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "80%",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        style={{ color: "grey", marginLeft: 10 }}
                    >
                        Automatic Tab Opener
                    </Typography>
                </div>
                <Divider
                    style={{
                        width: "90%",
                        margin: 10,
                        background: "transparent",
                    }}
                />
                <div
                    style={{
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginLeft: "5px",
                        marginRight: "10px",
                    }}
                >
                    <Typography
                        variant="body1"
                        style={{ color: "grey" }}
                        align="justify"
                    >
                        Our extension, "Automatic Tab Opener" allows you to
                        automate the opening of multiple URLs based on a cron
                        expression or predefined intervals (e.g., daily, hourly,
                        every minute).
                    </Typography>

                    <Typography
                        variant="body1"
                        style={{ color: "grey" }}
                        align="justify"
                    >
                        Simply input the URLs you want to open and specify the
                        frequency at which they should be opened. The cron
                        expression follows the standard format, with the ability
                        to specify minute, hour, day of the month, month, and
                        day of the week. For example, to open the URLs every day
                        at 8am, you would enter "0 8 * * *". Or, you can choose
                        one of the predefined intervals (e.g., daily, hourly,
                        every minute) from the dropdown menu. The extension will
                        then open the specified URLs at the specified frequency,
                        saving you time and effort in manually opening them
                        yourself.
                    </Typography>

                    <Typography
                        variant="body1"
                        style={{ color: "grey" }}
                        align="justify"
                    >
                        With "Automatic Tab Opener" you can streamline your
                        daily tasks and focus on more important things.
                    </Typography>
                </div>
                <Divider
                    style={{
                        width: "0%",
                        margin: 15,
                        background: "transparent",
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                    }}
                >
                    <Card
                        sx={{ display: "flex" }}
                        style={{
                            margin: "15px",
                            backgroundColor: "transparent",
                            boxShadow: "10px 10px 6px 1px rgba(0,0,0,0.75)",
                        }}
                        variant="outlined"
                    >
                        <CardActionArea
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "15px",
                            }}
                            onClick={() => goToChrome()}
                            onAuxClick={() => goToChrome(true)}
                        >
                            <CardMedia
                                component="img"
                                sx={{
                                    width: "15vh",
                                    height: "15vh",
                                    backgroundColor: "grey",
                                }}
                                src={chrome}
                            />
                            <Typography
                                component="div"
                                variant="h5"
                                style={{ color: "grey", marginTop: "15px" }}
                            >
                                Chrome
                            </Typography>
                        </CardActionArea>
                    </Card>
                    <Card
                        sx={{ display: "flex" }}
                        style={{
                            margin: "15px",
                            backgroundColor: "transparent",
                            boxShadow: "10px 10px 6px 1px rgba(0,0,0,0.75)",
                        }}
                        variant="outlined"
                    >
                        <CardActionArea
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "15px",
                            }}
                            onClick={() => goToFirefox()}
                            onAuxClick={() => goToFirefox(true)}
                        >
                            <CardMedia
                                component="img"
                                sx={{
                                    width: "15vh",
                                    height: "15vh",
                                    backgroundColor: "grey",
                                }}
                                src={firefox}
                            />
                            <Typography
                                component="div"
                                variant="h5"
                                style={{ color: "grey", marginTop: "15px" }}
                            >
                                Firefox
                            </Typography>
                        </CardActionArea>
                    </Card>
                    <Card
                        sx={{ display: "flex" }}
                        style={{
                            margin: "15px",
                            backgroundColor: "transparent",
                            boxShadow: "10px 10px 6px 1px rgba(0,0,0,0.75)",
                        }}
                        variant="outlined"
                    >
                        <CardActionArea
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "15px",
                            }}
                            onClick={() => goToEdge()}
                            onAuxClick={() => goToEdge(true)}
                        >
                            <CardMedia
                                component="img"
                                sx={{ width: '16vh', height: '16vh', backgroundColor: 'grey' }}
                                src={edge}
                            />
                            <Typography
                                component="div"
                                variant="h5"
                                style={{ color: "grey", marginTop: "15px" }}
                            >
                                Edge
                            </Typography>
                        </CardActionArea>
                    </Card>
                    <Card
                        sx={{ display: "flex", maxWidth: 450 }}
                        style={{
                            margin: "15px",
                            backgroundColor: "transparent",
                            boxShadow: "10px 10px 6px 1px rgba(0,0,0,0.75)",
                        }}
                        variant="outlined"
                    >
                        <CardActionArea
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "15px",
                            }}
                            onClick={() => goToOpera()}
                            onAuxClick={() => goToOpera(true)}
                        >
                            <CardMedia
                                component="img"
                                sx={{ width: '16vh', height: '16vh', backgroundColor: 'grey' }}
                                src={opera}
                            />
                            <Typography
                                component="div"
                                variant="h5"
                                style={{ color: "grey", marginTop: "15px" }}
                            >
                                Opera
                            </Typography>
                        </CardActionArea>
                    </Card>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default AutoTabOpener;
