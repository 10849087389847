import { Helmet } from 'react-helmet';

const SeoHelmet = (props) => {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
    );
};

export default SeoHelmet;
