import SeoHelmet from "../components/SeoHelmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./Homepage.css";

const privacyPolicy = `Nuno Reis built the Birthday Reminder app as a free app.
This SERVICE is provided by Nuno Reis at no cost and is intended for use as is.
This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service.
If you choose to use my Service, then you agree to the collection and use of information in relation to this policy.
The Personal Information that I collect is used for providing and improving the Service.
I will not use or share your information with anyone except as described in this Privacy Policy.
The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at Birthday Reminder unless otherwise defined in this Privacy Policy.`;

const informationCollection = `For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information.
The personal information that I collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use.
The personal information we collect may include the following: name, email adress, and authentication data.`;

const logData = `I want to inform you that whenever you use my Service,
in a case of an error in the app I collect data and information (through third-party products) on your phone called Log Data.
This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version,
the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.`;

const cookies = `Cookies are files with a small amount of data that are
commonly used as anonymous unique identifiers. These are
sent to your browser from the websites that you visit and
are stored on your device's internal memory.
This Service does not use these “cookies” explicitly.
However, the app may use third-party code and libraries that
use “cookies” to collect information and improve their
services. You have the option to either accept or refuse
these cookies and know when a cookie is being sent to your
device. If you choose to refuse our cookies, you may not be
able to use some portions of this Service.`;

const childrenPrivacy = `These Services do not address anyone under the age of
13. I do not knowingly collect personally identifiable
information from children under 13 years of age. In the
case I discover that a child under 13 has provided me
with personal information, I immediately delete this
from our servers. If you are a parent or guardian and
you are aware that your child has provided us with
personal information, please contact me so that I will
be able to do the necessary actions.`;

const deleteData = `Based on the applicable laws of your country,
you may have the right to delete the personal information we collect from you.
To delete your personal information, please follow these steps. First open the application and authenticate yourself.
After you've been successfully authenticated click on your avatar which will open a new window with an option to delete your data.
By clicking on this option, we will deactivate or delete your account and information from our active databases.
However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.`;

const Privacy = () => {
    return (
        <div className="Container">
            <SeoHelmet
                title="InstanceOf | Privacy Policy"
                description="InstanceOf | The Privacy Policy of Birthday Reminder App"
            />
            <Navbar />

            <div
                className="Left-Content"
                style={{
                    textAlign: "left",
                    alignItems: "flex-start",
                }}
            >
                <h3>Privacy Policy</h3>
                <text>{privacyPolicy}</text>

                <h4>Information Collection and Use</h4>
                <text>{informationCollection}</text>
                <text>
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal
                    requirements). No purpose in this notice will require us
                    keeping your personal information for longer than the period
                    of time in which users have an account with us.
                </text>

                <h4>Log Data</h4>
                <text>{logData}</text>

                <h4>Cookies</h4>
                <text>{cookies}</text>

                <h4>Service Providers</h4>
                <text>
                    I may employ third-party companies and individuals due to
                    the following reasons:
                </text>
                <ul>
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <text>
                    I want to inform users of this Service that these third
                    parties have access to their Personal Information. The
                    reason is to perform the tasks assigned to them on our
                    behalf. However, they are obligated not to disclose or use
                    the information for any other purpose.
                </text>
                <h4>Security</h4>
                <text>
                    I value your trust in providing us your Personal
                    Information, thus we are striving to use commercially
                    acceptable means of protecting it. But remember that no
                    method of transmission over the internet, or method of
                    electronic storage is 100% secure and reliable, and I cannot
                    guarantee its absolute security.
                </text>
                <h4>Links to Other Sites</h4>
                <text>
                    This Service may contain links to other sites. If you click
                    on a third-party link, you will be directed to that site.
                    Note that these external sites are not operated by me.
                    Therefore, I strongly advise you to review the Privacy
                    Policy of these websites. I have no control over and assume
                    no responsibility for the content, privacy policies, or
                    practices of any third-party sites or services.
                </text>
                <h4>Children’s Privacy</h4>
                <text>{childrenPrivacy}</text>

                <h4>Collected Data Deletion</h4>
                <text>{deleteData}</text>

                <h4>Changes to This Privacy Policy</h4>
                <text>
                    I may update our Privacy Policy from time to time. Thus, you
                    are advised to review this page periodically for any
                    changes. I will notify you of any changes by posting the new
                    Privacy Policy on this page.
                </text>
                <div style={{ alignSelf: "flex-end" }}>
                    <text>This policy is effective as of 2023-02-18</text>
                </div>
                <h4>Contact Us</h4>
                <text>
                    If you have any questions or suggestions about my Privacy
                    Policy, do not hesitate to contact me at{" "}
                    <a href="mailto:nuno.reis@instanceof.dev">
                        nuno.reis@instanceof.dev
                    </a>
                    .
                </text>
            </div>

            <Footer />
        </div>
    );
};

export default Privacy;
