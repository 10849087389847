import * as React from 'react';
import ReactGA from 'react-ga4';
import {useNavigate} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Home from '@mui/icons-material/Home';

const headerStyle = {
    display: 'flex',
    height: '65px',
    position: 'fixed',
    top: 0,
    marginLeft: '2%',
    marginRight: '2%',
    width: '96%',
    backgroundColor: '#282c34',
    boxShadow: 'none',
    borderBottom: '1px solid grey',
};

const ResponsiveAppBar = () => {
    const navigate = useNavigate();
  
    const goToBlog = (newTab) => {
        ReactGA.event({
            category: "Medium",
            action: "User pressed Blog",
        });
        window.open("https://medium.com/@no0law1?utm_source=instanceof", newTab ? '_blank' : '_self');
        // navigate('/tech-corner');
    };

    const goToTechCorner = () => {
        ReactGA.event({
            category: "Medium",
            action: "User pressed TechCorner",
        });
        navigate('/tech-corner');
    };

    // const goToTravelCorner = () => {
    //     ReactGA.event({
    //         category: "TravelCorner",
    //         action: "User pressed TravelCorner",
    //     });
    //     navigate('/travel-corner');
    // };

    const goToAbout = () => {
        ReactGA.event({
            category: "About",
            action: "User pressed About",
        });
        navigate('/about');
    };

    // const goToContact = () => {
    //     ReactGA.event({
    //         category: "Contact",
    //         action: "User pressed Contact",
    //     });
    //     navigate('/contact');
    // };

    const goToHome = () => {
        ReactGA.event({
            category: "Home",
            action: "User pressed Home",
        });
        navigate('/');
    }

  return (
    <AppBar style={headerStyle}>
        <Toolbar disableGutters>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <Box>
                    <IconButton style={{color: 'grey'}} aria-label="home" onClick={goToHome}>
                        <Home />
                    </IconButton>
                    <Button
                        style={{color: 'grey'}}
                        onClick={() => goToBlog()}
                        onAuxClick={() => goToBlog(true)}>
                            Blog
                    </Button>
                    <Button
                        style={{color: 'grey'}}
                        onClick={goToTechCorner}>
                            Tech Corner
                    </Button>
                    {/* <Button
                        style={{color: 'grey'}}
                        onClick={goToTravelCorner}>
                            Travel Corner
                    </Button> */}
                </Box>
                <Box>
                    <Button
                        style={{color: 'grey'}}
                        onClick={goToAbout}>
                            About
                    </Button>
                    {/* <Button
                        style={{color: 'grey'}}
                        onClick={goToContact}>
                            Contact
                    </Button> */}
                </Box>
        </Box>
        </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
