import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
import { Typography, Divider } from "@mui/material";
import SeoHelmet from "../components/SeoHelmet";

const BirthdayReminder = () => {
    const goToPlayStore = (newTab) => {
        ReactGA.event({
            category: "BirthdayReminder",
            action: "User pressed Play Store",
        });
        window.open(
            "https://play.google.com/store/apps/details?id=com.nunoreis.birthdayreminder&utm_source=instanceof",
            newTab ? "_blank" : "_self"
        );
        console.log("Stuff!");
    };

    return (
        <div className="Container">
            <SeoHelmet
                title="InstanceOf | Birthday Reminder"
                description="Birthday Reminder Android Application"
            />
            <Navbar />

            <div
                className="Centered-Content"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "80%",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        style={{ color: "grey", marginLeft: 10 }}
                    >
                        Birthday Reminder
                    </Typography>
                </div>
                <Divider
                    style={{
                        width: "90%",
                        margin: 10,
                        background: "transparent",
                    }}
                />
                <div
                    style={{
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginLeft: "5px",
                        marginRight: "10px",
                    }}
                >
                    <Typography
                        variant="body1"
                        style={{ color: "grey" }}
                        align="center"
                    >
                        Birthday Reminder is a mobile app that helps users keep
                        track of the birthdays of their friends and family. The
                        app allows users to add the names and birthdays of their
                        loved ones to a virtual list, and sends a notification
                        to the user on the day of the person's birthday,
                        reminding them to send a birthday message or wish.
                    </Typography>

                    <Typography
                        variant="body1"
                        style={{ color: "grey" }}
                        align="center"
                    >
                        To use the app, users need to log in with their Facebook
                        account. To create an account using Facebook, users
                        simply need to log in with their Facebook login
                        credentials when prompted. The app will then retrieve
                        the user's name and email address from Facebook and use
                        this information to create a new account for the user
                        within the app.
                    </Typography>

                    <Typography
                        variant="body1"
                        style={{ color: "grey" }}
                        align="center"
                    >
                        Once logged in, users can access the app's main menu and
                        add new birthdays to their list. They can also customize
                        the notification settings within the app, choosing to
                        receive notifications at a specific time of day, or opt
                        to receive them as a push notification on their phone.
                        They can also choose whether to receive notifications
                        for just their own birthdays, or for the birthdays of
                        all the people on their list. Overall, Birthday Reminder
                        is a useful and convenient tool for helping users stay
                        organized and remembering to wish their loved ones a
                        happy birthday.
                    </Typography>

                    <Typography
                        variant="body1"
                        style={{ color: "grey" }}
                        align="center"
                    >
                        Overall, Birthday Reminder is a useful and convenient
                        tool for helping users stay organized and remembering to
                        wish their loved ones a happy birthday.
                    </Typography>
                </div>
                <Divider
                    style={{
                        width: "0%",
                        margin: 15,
                        background: "transparent",
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                    }}
                >
                    <img
                        onClick={() => goToPlayStore()}
                        onAuxClick={() => goToPlayStore(true)}
                        style={{ height: "100px", cursor: "pointer" }}
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default BirthdayReminder;
