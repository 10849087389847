import ReactGA from 'react-ga4';
import BottomNavigation from '@mui/material/BottomNavigation';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import CoffeeIcon from '@mui/icons-material/Coffee';

const footerStyle = {
    backgroundColor: '#282c34',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    color: 'grey',
    borderTop: '1px solid grey',
    width: '96%',
    marginLeft: '2%',
    marginRight: '2%',
    height: '55px'
};

const Footer = () => {
    return (
        <BottomNavigation style={footerStyle} >
            <div style={{order: 1, width: '50%', textAlign: 'left'}}>© {new Date().getFullYear()} Nuno Reis</div>
            <div style={{order: 2, width: '50%', textAlign: 'right'}}>
                <IconButton
                    onClick={() => onClickLinkedIn()}
                    onAuxClick={() => onClickLinkedIn(true)}
                    aria-label="LinkedIn"
                    component="span">
                        <LinkedInIcon />
                </IconButton>
                <IconButton
                    onClick={() => onClickTwitter()}
                    onAuxClick={() => onClickTwitter(true)}
                    aria-label="Twitter"
                    component="span">
                        <TwitterIcon />
                </IconButton>
                <IconButton
                    onClick={() => onClickBuyMeCoffee()}
                    onAuxClick={() => onClickBuyMeCoffee(true)}
                    aria-label="Buy Me a Coffee"
                    component="span">
                        <CoffeeIcon />
                </IconButton>
            </div>
        </BottomNavigation>
    )
};

function onClickLinkedIn(newTab){
    ReactGA.event({
        category: "LinkedIn",
        action: "User pressed LinkedIn icon",
    });
    window.open("https://www.linkedin.com/in/nuno-reis-520301b6/?utm_source=instanceof", newTab ? '_blank' : '_self');
}

function onClickTwitter(newTab){
    ReactGA.event({
        category: "Twitter",
        action: "User pressed Twitter icon",
    });
    window.open("https://twitter.com/no0law1?utm_source=instanceof", newTab ? '_blank' : '_self');
}

function onClickBuyMeCoffee(newTab){
    ReactGA.event({
        category: "BuyMeCoffee",
        action: "User pressed BuyMeCoffee icon",
    });
    window.open("https://www.buymeacoffee.com/no0law1?utm_source=instanceof", newTab ? '_blank' : '_self');
}

export default Footer;
