import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SeoHelmet from "../components/SeoHelmet";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import me from "../static/me.jpg";

const about = `An enthusiastic and skilled software developer with a Bachelor's degree from Instituto Superior de Engenharia de Lisboa, where I achieved a Final Average of 15 in Computer Science and Engineering.
Possessing strong interpersonal skills, I am a friendly, open-minded, creative and fun team player who is passionate about my work.
With extensive experience in designing and implementing micro service architecture applications using Kafka and multiple databases, I also have a solid background in React and React Native.
Currently serving as a Senior Software Developer at Farfetch, with a proven track record of delivering high-quality services for the Farfetch platform using Dotnet and Kubernetes.`;

const About = () => {
    return (
        <div className="Container">
            <SeoHelmet
                title="InstanceOf | About"
                description="InstanceOf | All you need to know about Nuno"
            />
            <Navbar />

            <div className="Centered-Content">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "80%",
                        alignItems: "center",
                    }}
                >
                    <Avatar alt="Nuno Reis" src={me} />
                    <Typography
                        variant="h4"
                        style={{ color: "grey", marginLeft: 10 }}
                    >
                        Nuno Reis
                    </Typography>
                </div>
                <Divider
                    style={{
                        width: "90%",
                        margin: 10,
                        background: "transparent",
                    }}
                ></Divider>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "80%",
                    }}
                >
                    {about.split("\n").map((paragraph) => {
                        return (
                            <Typography
                                variant="body1"
                                style={{ color: "grey" }}
                                align="justify"
                            >
                                {paragraph}
                            </Typography>
                        );
                    })}
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default About;
