import * as React from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import SeoHelmet from "../components/SeoHelmet";
import financeInternIcon from "../static/finance_intern.png";
import autoTabOpenerIcon from "../static/auto_tab_opener.png";
import birthdayReminderIcon from "../static/birthday_reminder.png";
import euribotIcon from "../static/euribot.png";
import { CardActionArea } from "@mui/material";

const TechCorner = () => {
    const navigate = useNavigate();
    const goToFinanceIntern = (newTab) => {
        ReactGA.event({
            category: "FinanceIntern",
            action: "User pressed FinanceIntern",
        });
        window.open(
            "https://play.google.com/store/apps/details?id=com.financeintern&utm_source=instanceof",
            newTab ? "_blank" : "_self"
        );
        // navigate('/tech-corner/finance-intern');
    };

    const goToAutoTabOpener = (newTab) => {
        ReactGA.event({
            category: "AutoTabOpener",
            action: "User pressed AutoTabOpener",
        });
        navigate("/tech-corner/auto-tab-opener");
    };

    const goToBirthdayReminder = (newTab) => {
        ReactGA.event({
            category: "BirthdayReminder",
            action: "User pressed BirthdayReminder",
        });
        navigate("/tech-corner/birthday-reminder");
    };

    const goToEuribot = (newTab) => {
        ReactGA.event({
            category: "Euribot",
            action: "User pressed Euribot",
        });
        window.open(
            "https://twitter.com/TheEuribot?utm_source=instanceof",
            newTab ? "_blank" : "_self"
        );
        // navigate('/tech-corner/euribot');
    };

    return (
        <div className="Container">
            <SeoHelmet
                title="InstanceOf | TechCorner"
                description="InstanceOf | The Tech Corner of Nuno Reis"
            />
            <Navbar />

            <div
                className="Centered-Content"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                }}
            >
                <Card className="Card" variant="outlined">
                    <CardActionArea
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                        onClick={() => goToBirthdayReminder()}
                        onAuxClick={() => goToBirthdayReminder(true)}
                    >
                        <CardMedia
                            component="img"
                            sx={{
                                width: 151,
                                height: 151,
                                backgroundColor: "grey",
                            }}
                            src={birthdayReminderIcon}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    component="div"
                                    variant="h5"
                                    style={{ color: "grey" }}
                                >
                                    Birthday Reminder
                                </Typography>
                                <Typography
                                    style={{ color: "grey" }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    Helps users keep track of the birthdays of
                                    their friends and family.
                                </Typography>
                            </CardContent>
                        </Box>
                    </CardActionArea>
                </Card>
                <Card className="Card" variant="outlined">
                    <CardActionArea
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                        onClick={() => goToAutoTabOpener()}
                        onAuxClick={() => goToAutoTabOpener(true)}
                    >
                        <CardMedia
                            component="img"
                            sx={{
                                width: 151,
                                height: 151,
                                backgroundColor: "grey",
                            }}
                            src={autoTabOpenerIcon}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    component="div"
                                    variant="h5"
                                    style={{ color: "grey" }}
                                >
                                    Automatic Tab Opener
                                </Typography>
                                <Typography
                                    style={{ color: "grey" }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    Open multiple tabs periodically based on a
                                    cron expression
                                </Typography>
                            </CardContent>
                        </Box>
                    </CardActionArea>
                </Card>
                <Card className="Card" variant="outlined">
                    <CardActionArea
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                        onClick={() => goToFinanceIntern()}
                        onAuxClick={() => goToFinanceIntern(true)}
                    >
                        <CardMedia
                            component="img"
                            sx={{
                                width: 151,
                                height: 151,
                                backgroundColor: "grey",
                            }}
                            src={financeInternIcon}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    component="div"
                                    variant="h5"
                                    style={{ color: "grey" }}
                                >
                                    Finance Intern
                                </Typography>
                                <Typography
                                    style={{ color: "grey" }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    Multiple financial calculators to aid you
                                    with everyday life
                                </Typography>
                            </CardContent>
                        </Box>
                    </CardActionArea>
                </Card>

                <Card className="Card" variant="outlined">
                    <CardActionArea
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                        onClick={() => goToEuribot()}
                        onAuxClick={() => goToEuribot(true)}
                    >
                        <CardMedia
                            component="img"
                            sx={{
                                width: 151,
                                height: 151,
                                backgroundColor: "grey",
                            }}
                            src={euribotIcon}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    component="div"
                                    variant="h5"
                                    style={{ color: "grey" }}
                                >
                                    Euribot
                                </Typography>
                                <Typography
                                    style={{ color: "grey" }}
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    Provides euribor rates information to
                                    Twitter users.
                                </Typography>
                            </CardContent>
                        </Box>
                    </CardActionArea>
                </Card>
            </div>

            <Footer />
        </div>
    );
};

export default TechCorner;
