import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import List from '@mui/material/List';

const countries = [
    {
        img: "",
        description: "2018 | Croatia"
    },
    {
        img: "",
        description: "2020 | Cuba"
    },
]

const TravelCorner = () => {
    return (
        <div className="Container">
            <Navbar />

            <div className="Centered-Content">
                <List>

                </List>
                {
                    countries.map(c => {
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80%'}}>
                                <Avatar src={c.img} />
                                <Typography variant='body2' style={{color: 'grey', marginLeft: 10 }}>{c.description}</Typography>
                            </div>
                        );
                    })
                }
            </div>

            <Footer />
        </div>
    );
};

export default TravelCorner;
