import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { TextField, Divider, Button } from '@mui/material';

const Contact = () => {
    return (
        <div className="Container">
            <Navbar />

            <div className="Centered-Content">
                <div style={{width: '80%'}}>
                    <TextField
                        inputProps={{style:{borderColor: 'grey'}}}
                        fullWidth
                        required
                        margin="dense"
                        variant="outlined"
                        size="small"
                        label="Email" />
                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        multiline
                        label="Place your request..." />
                </div>
                <Divider style={{width: '90%', margin: 10, background: 'transparent'}}></Divider>
                <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: '80%'}}>
                    <Button variant="contained" size="small" style={{background:'grey'}}>Submit</Button>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Contact;
